import React from "react"
import { graphql, Link, navigate } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import {
  makeStyles,
  createStyles,
  useTheme,
  Theme,
} from "@material-ui/core/styles"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"

import Layout from "../components/Layout"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    underline: {
      borderbottom: `3px solid ${theme.palette.primary.main}`,
      width: "fit-content",
    },
    fadeText: {
      background: `linear-gradient( 180deg, ${theme.palette.background.paper}00 0%, ${theme.palette.background.paper}66 20%, ${theme.palette.background.paper}FF 100%)`,
    },
  })
)

const ProjectList = ({ data }) => {
  const theme = useTheme()
  const classes = useStyles()
  const { allMarkdownRemark } = data
  console.log(data)
  return (
    <div>
      <Layout>
        <Box p={["1rem", "2rem", "3rem"]}>
          <Box
            pl={[".5rem"]}
            fontWeight="fontWeightLight"
            fontFamily="Pacifico"
            color={theme.palette.primary.main}
            fontSize="h1.fontSize"
            textAlign="center"
          >
            Our Projects
          </Box>
        </Box>
        <Grid container justify="space-evenly" spacing={2}>
          {allMarkdownRemark.edges.map(({ node }, index) => (
            <Grid item xs={12} md={5} key={index}>
              <Box
                display="flex"
                flexDirection="column"
                borderRadius="8px"
                bgcolor={theme.palette.opaque.default}
                pl={[".5rem"]}
                pr={[".5rem"]}
                pt={["1rem"]}
                pb={["2rem"]}
              >
                <Box
                  display="flex"
                  bgcolor={theme.palette.primary.light}
                  p={["1rem"]}
                  height="30%"
                >
                  <GatsbyImage
                    image={getImage(node.frontmatter.images[0].image)}
                    alt=""
                    style={{ width: "100%" }}
                    loading="eager"
                    objectFit="cover"
                  />
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  bgcolor={theme.palette.background.paper}
                >
                  <Box
                    display="flex"
                    minHeight={["0px", "5rem"]}
                    pl={[".5rem"]}
                    pr={[".5rem"]}
                    pb={["1rem"]}
                    fontWeight="fontWeightRegular"
                    fontSize="h4.fontSize"
                    alignItems="center"
                  >
                    {node.frontmatter.title}
                  </Box>
                  <Box
                    component="div"
                    overflow="hidden"
                    display="flex"
                    flexDirection="column"
                    fontSize="h6.fontSize"
                    fontWeight="fontWeightLight"
                    pl={[".5rem"]}
                    pr={[".5rem"]}
                    textAlign="justify"
                    height={["7rem", "8rem"]}
                  >
                    <Box display="flex">{node.frontmatter.description}</Box>
                  </Box>
                  <Box
                    className={classes.fadeText}
                    display="block"
                    position="relative"
                    top="-3rem"
                    height="3rem"
                  />
                  <Box display="flex" justifyContent="flex-end">
                    <Box
                      pr={["1rem"]}
                      pb={["1rem"]}
                      fontWeight="fontWeightLight"
                      fontSize="button.fontSize"
                      textAlign="justify"
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => navigate(node.fields.slug)}
                      >
                        Learn more
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Layout>
    </div>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { fields: {}, frontmatter: { templateKey: { eq: "project" } } }
    ) {
      edges {
        node {
          frontmatter {
            templateKey
            title
            description
            details {
              detail {
                detail
                label
              }
            }
            location
            images {
              image {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                    aspectRatio: 1.77
                    formats: [AUTO, WEBP, AVIF]
                    quality: 80
                  )
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`

export default ProjectList
